import { gql } from 'graphql-request';

import {
  UserUUID,
  UserNanoID,
  UserID,
  UserTurnAroundTimeMonthly,
  UserClient,
  UserClientRateAverage,
  UserWorkerRateAverage,
  UserClientRateMedian,
  UserWorkerRateMedian,
  UserWorkExperience,
  UserCurrentTeamNanoID,
  UserBlocked
} from '../usersTypes';

export interface FetchUserDashboardQueryResponse {
  id: UserID;
  nanoId: UserNanoID;
  uuid: UserUUID;
  turnAroundTimeMonthly: UserTurnAroundTimeMonthly;
  blocked: UserBlocked;
  client: UserClient;
  clientRateAverage: UserClientRateAverage;
  workerRateAverage: UserWorkerRateAverage;
  clientRateMedian: UserClientRateMedian;
  workerRateMedian: UserWorkerRateMedian;
  workExperience: UserWorkExperience;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
  };
}

export const FETCH_USER_DASHBOARD_QUERY = gql`
  query UserDashboard($uuid: ID!) {
    user(uuid: $uuid) {
      id
      nanoId
      uuid
      blocked
      turnAroundTimeMonthly
      client
      clientRateAverage
      workerRateAverage
      clientRateMedian
      workerRateMedian
      workExperience
      currentTeam {
        nanoId
      }
    }
  }
`;
