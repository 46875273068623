import React, { Fragment } from 'react';

import { TeamNanoID, TeamOwnerNanoID } from '../../../teamsTypes';

import {
  FETCH_USER_DASHBOARD_QUERY,
  FetchUserDashboardQueryResponse
} from '../../../../users/queries/fetchUserDashboard.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUser } from '../../../../users/hooks/useUser';

import { DashboardStatistic } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardStatistic';
import { DashboardFinance } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardFinance';
import { DashboardTasksByStatus } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardTasksByStatus';
import { DashboardActiveTasks } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardActiveTasks';
import { DashboardTrackedTime } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardTrackedTime';
import { DashboardMissingSourceFiles } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardMissingSourceFiles';
import { DashboardLatestResults } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardLatestResults';

import { MenuCreateProjectInTeamLink } from '../../../../common/components/menus/MenuCreateProjectInTeamLink';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { CommonPermissions } from '../../../../common/commonConstants';
import { UsersPermissions } from '../../../../users/usersConstants';
import { UserCache } from '../../../../users/UserCache';

interface TeamDashboardIndexPageProps {
  ownerNanoId: TeamOwnerNanoID;
  teamNanoId?: TeamNanoID;
}

function TeamDashboardIndexPage({
  ownerNanoId,
  teamNanoId
}: TeamDashboardIndexPageProps) {
  const cacheKey = UserCache.userDashboardKey();

  const currentUser = useCurrentUser();

  const { user, userFetched, userError } =
    useUser<FetchUserDashboardQueryResponse>({
      cacheKey,
      uuid: ownerNanoId,
      query: FETCH_USER_DASHBOARD_QUERY
    });

  const selfProfile = ownerNanoId === currentUser.nanoId;

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={userError} />
      <LoadingSkeleton loaded={userFetched}>
        {user ? (
          <div className="space-y-16">
            <CheckPermissions
              action={
                CommonPermissions.READ_WORKSPACE_MENU_CREATE_PROJECT_IN_TEAM_LINK
              }
            >
              <MenuCreateProjectInTeamLink />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_LATEST_RESULTS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_LATEST_RESULTS_BLOCK
              }
            >
              <DashboardLatestResults selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK
              }
            >
              <DashboardActiveTasks selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              actions={[
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK,
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
              ]}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <CheckPermissions
                  action={
                    selfProfile
                      ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
                      : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK
                  }
                >
                  <DashboardTrackedTime
                    user={user}
                    teamNanoId={teamNanoId}
                    selfProfile={selfProfile}
                  />
                </CheckPermissions>

                <CheckPermissions
                  action={
                    selfProfile
                      ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                      : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                  }
                >
                  <DashboardMissingSourceFiles
                    user={user}
                    teamNanoId={teamNanoId}
                    selfProfile={selfProfile}
                  />
                </CheckPermissions>
              </div>
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_BY_STATUS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_TASKS_BY_STATUS_BLOCK
              }
            >
              <DashboardTasksByStatus selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_FINANCE_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_FINANCE_BLOCK
              }
            >
              <DashboardFinance
                selfProfile={selfProfile}
                teamNanoId={teamNanoId}
                client={user.client}
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_STATISTIC_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_STATISTIC_BLOCK
              }
            >
              <DashboardStatistic
                selfProfile={selfProfile}
                user={user}
                teamNanoId={teamNanoId}
              />
            </CheckPermissions>
          </div>
        ) : null}
      </LoadingSkeleton>
    </Fragment>
  );
}

export default TeamDashboardIndexPage;
